import React from 'react';
import '../App.css';
import Unfun from './unfun'
import Fun from './fun'



export default function Main(props){
    console.log(props)
    return(
        <>
        {(localStorage.getItem('fun') === 'true') ?
        <Fun />: <Unfun />
        }
        </>
    )
}