import React, { useState } from 'react';
import Emailer from './Emailer';
import '../App.css';
import Background from '../video';


export default function Contact() {

    return (
        <>
            <Background />
            <h1 className="contactHead">Get in touch with me!</h1>
            <Emailer />

        </>
    )
}