  
import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom'

const Ul = styled.ul`
    display: flex;
    flex-direction: column;
    flex-flow: column nowrap;
    background-color: silver;
    border-left: 2px solid black;
    position: fixed;
    margin-top: 0;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    z-index: 2;
    li {
      color: #fff;
    }
`
const Shimmer = styled.a`
    background-image: -webkit-linear-gradient(left, #4C0099 25%, #fde071 37.5%, #ef9671, #996a6e 62.5%, blue 75%);
    background-image: linear-gradient(90deg, #4C0099 25%, #fde071 37.5%, #ef9671, #996a6e 62.5%, black 75%);
    background-position: right center;
    background-size: 400% auto;
    -webkit-background-clip: text;
    background-clip: text;
    color: white;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    transition: color 200ms linear;
    font-family: 'Oswald', sans-serif;
    font-size: 3.4rem;
    margin: 0;
    width: auto;
    text-align: center;
  
@media(max-width: 800px){
    font-size: 2.2rem;
}

  &:hover {
    background-position: left center;
    color: blueviolet;
    transition: background-position 2000ms ease-out;
  }
  
  `



const RightNav = ({ open }) => {
  return (
    <Ul open={open}>
            <Shimmer  href="/home#Home"> Home </Shimmer>
            <Shimmer  href="/home#About" >About Me </Shimmer>
            <Shimmer href="/Projects" >Projects</Shimmer>
            <Shimmer href="/Contact" >Contact Me</Shimmer>
    </Ul>
  )
}

export default RightNav