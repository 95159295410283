import React, { useState } from 'react'
import styled from 'styled-components'
import Carosel from './FunProjects/Carosel'

export default function Middle() {
  const Background = styled.div`
    background-image: url(https://thedevcouple.com/wp-content/uploads/2017/10/Interview-React-2.jpg);
    background-size: cover;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0;
  `
  const Header = styled.h1`
    color: orange;
    margin: 0;
    text-align: center;
    font-size: 4rem;
  `
  const Projects = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
    padding-top: 2.5vh;
  `
  return (
    <>
      <Background>
        <Projects>
          <Carosel />
        </Projects>
      </Background>
    </>
  )
}
