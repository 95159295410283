import React, { useState, useEffect } from 'react'
import './projects.css'

export default function SlideShow(props) {
    const pics = [props.picture1, props.picture2, props.picture3]

    const [picture, setPicture] = useState(0)

    setTimeout(function () {
        if (picture < pics.length - 1) { setPicture(picture + 1) }
        else { setPicture(0) }
    }, 6000);
    return (
        <div className='example-backdrop'>
            <img className="example" src={require(`${pics[picture]}`)} />
        </div>
    )
}