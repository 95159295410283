import React from 'react'
import Logo from './Logo'
import styled from 'styled-components'
import { AnimatePresence, motion } from "framer-motion";
import {pageStyle, pageVariants2, pageTransition } from '../utils/PageTransitions'
export default function Welcome() {

    const BackDrop = styled.div`
    position: fixed;
    z-index: -11;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    background-color: black;
    justify-content: center;
    flex-direction: column;
    `

    
    return (
        <motion.div
        className="row"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants2}
        transition={pageTransition}
      >
        <BackDrop>
            <Logo />
        </BackDrop>
        </motion.div>
    )
}