import React from 'react';
import ReactDOM from 'react-dom';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryAnimation } from 'victory';

export default function Chart(props) {
    console.log(props.skillsClicked)
    const data = [
        { language: 1, skill: 3 },
        { language: 2, skill: 2 },
        { language: 3, skill: 3 },
        { language: 4, skill: 2 },
        { language: 5, skill: 1 },
        { language: 6, skill: 3 }
    ];

    if(props.skillsClicked == true){
    return (
        <VictoryChart domainPadding={30} style={{ parent: { maxWidth: "70%" } }}>
            <VictoryAxis
                tickValues={[6, 1, 3, 4, 5, 2]}
                tickFormat={["HTML", "CSS", `JS`, "React", "NODE", "Java"]}
            />
            <VictoryAxis font-size={'12px'}
                dependentAxis
                tickValues={[1, 2, 3, 4]}
                tickFormat={["Basic", "Intermediate", "Advanced", "Expert"]}
            />
            <VictoryBar
                style={{
                    data: { fill: "#601AE3" }
                }}
                animate={{
                    duration: 3000,
                }}
            horizontal
                    data={data}
            x="language"
            y="skill" />
            </VictoryChart>
    )}
    else{
        return(null)
    }
}