import React, { useState } from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal';
import Emailer from '../Contact/NoFunContact'
import Projects from '../Projects/project'
import Footer from '../Footer/Footer'
import { AnimatePresence, motion } from "framer-motion";
import { pageStyle, pageVariants, pageTransition } from '../utils/PageTransitions'
import Background from '../Background/Background';
import {useScroll} from '../utils/UseScroll'


const Page = styled.div`
width: 100%;
position: relative;
background-color: black;
z-index: -10;
height: 100%;
margin:0;
padding:0;
display: flex;
flex-direction: column;
`

const Image = styled.img`
width: 25%;
margin: 2%;
border-radius:50%;
max-height: 1000px;
max-width: 880px;
border: 4px solid black;
@media(max-width: 800px){
    width: 40%;
}
`

const Centerer = styled.div`
width: 100%;
display: flex;
justify-content: center;
text-align: center;
z-index: 1;
position: relative;
`

const Name = styled.h1`
font-size: 4.5rem;
font-family: 'Dancing Script', cursive;
color: white;
z-index: 1;
position: relative;
margin: 0;
@media(max-width: 800px){
    font-size: 3rem;
}
`
const Title = styled.h1`
font-size: 3.5rem;
font-family: 'Dancing Script', cursive;
color: white;
z-index: 1;
position: relative;
`
const About = styled.div`
margin-top: 15rem;
width: 60%;
font-size: 1.6rem;
text-align: center;
color: white;
padding: 3% 11%;
z-index: 1;
position: relative;

@media(max-width: 800px){
    font-size: 1.2rem;
    width: 80%;
    padding: 3% 6%;
}
`
const AboutHeader = styled.h1`
font-size: 3rem;
text-decoration: underline;
text-align: center;
margin-top: 0;
z-index: 1;
position: relative;
padding: 0;
`
export default function Unfun() {
    const scroll = useScroll();

    return (
        <motion.div
            className="row"
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
        >   
            
            <Page id="Home" >
            <Background top ={scroll.y}/>
                <Centerer >
                    <Image src={require("../photos/68725151_2256442834477580_8427649252720640000_o (6).jpg")} />
                </Centerer>
                <Centerer><Name> Brendan Schatz</Name></Centerer>
                <Centerer><Title>Full Stack Developer</Title></Centerer>

                <Centerer id="About">
                    <About>
                        <AboutHeader>About Me</AboutHeader>
                            Hello, my name is Brendan Schatz. A little about me; I was going to school for accounting at Eastern Michigan University. After about three years I realised it wasn't the right path for me, I then got the oportunity to go to Lambda School and learn full stack web development. Through years in restauraunt work I learned how to be the best team player possible. Through training for triathlons I learned about the value of hard work. Now as a team lead and student at Lambda I am learning all about time management, leadership, and patience. I am currently looking for the next challenge in my life.
                        </About>
                </Centerer>
            </Page>
        </motion.div>
    )
}
