import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import Chart from './Chart'
import '../App.css';

const Full = styled.div`
display: flex;
flex-direction: column;
`
const Page = styled.div`
text-align: center;
margin-bottom: auto;
margin-top: 0;
box-shadow: inset 0 0 0 100vw rgba(350,0,0,0.2);
background-image: url(https://images.unsplash.com/photo-1525547719571-a2d4ac8945e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1900&q=80);
background-repeat: no-repeat;
background-position: center center;
height: 100vh;
width: 100vw;
display:flex;
justify-content: center;
`
const Header = styled.h1`
font-size: 4rem;
color: #c8d2c7;
margin: 0;
`
const Box = styled.div`

width: 60%;
height: 30%;
margin: 1%;
margin-top: 1%;
display:flex;
align-items: center;
justify-content: center;
flex-direction: column;
`

const Outline = styled.div`
display: flex;
width: 90%;
justify-content: center;
flex-wrap: wrap;
align-items: center;
`

const Subhead = styled.h3`
color: #c8d2c7;
font-size: 2.4rem;
margin: 0;
`

const Button = styled.button`
border: solid white 2px;
width:  50%;
height: 65px;
font-size: 1.4rem;
color: #c8d2c7;
text-align: center;

background-color: rgba(19,141,255, .0);
-moz-transition: all .6s ease;
-webkit-transition: all .6s ease;
-o-transition: all .6s ease;
transition: all .6s ease;
i {
    border: solid black;
    border-width: 0 6px 6px 0;
    display: inline-block;
    padding: 3px;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transition: all .6s ease;
    -webkit-transition: all .6s ease;
    -o-transition: all .6s ease;
    transition: all .6s ease;
  }
  :hover { 
    -moz-transition: all .6s ease;
    -webkit-transition: all .6s ease;
    -o-transition: all .6s ease;
    transition: all .6s ease;
    background-color: rgba(131,239,203, .6);
    .right {
        transform: rotate(45deg);
        -moz-transition: all .6s ease;
        -webkit-transition: all .6s ease;
        -o-transition: all .6s ease;
        transition: all .6s ease;
    }
  }
`

const Buttons = styled.button`
border: solid white 2px;
width: 100%;
height: 45px;
font-size: 1.4rem;
color: #c8d2c7;
text-align: center;
margin-bottom: 10px;
background-color: rgba(19,141,255, .0);

-moz-transition: all .6s ease;
-webkit-transition: all .6s ease;
-o-transition: all .6s ease;
transition: all .6s ease;
i {
    border: solid black;
    border-width: 0 6px 6px 0;
    display: inline-block;
    padding: 3px;
  }
  
  .arrow {
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    -moz-transition: all .6s ease;
    -webkit-transition: all .6s ease;
    -o-transition: all .6s ease;
    transition: all .6s ease;
  }
  :hover { 
    -moz-transition: all .6s ease;
    -webkit-transition: all .6s ease;
    -o-transition: all .6s ease;
    transition: all .6s ease;
    background-color: rgba(131,239,203, .6);
    .down {
        transform: rotate(45deg);
        -moz-transition: all .6s ease;
        -webkit-transition: all .6s ease;
        -o-transition: all .6s ease;
        transition: all .6s ease;
    }
  .left {
    transform: rotate(135deg);
    -moz-transition: all .6s ease;
    -webkit-transition: all .6s ease;
    -o-transition: all .6s ease;
    transition: all .6s ease;
    }
    .right {
        transform: rotate(315deg);
        -moz-transition: all .6s ease;
        -webkit-transition: all .6s ease;
        -o-transition: all .6s ease;
        transition: all .6s ease;
    }
  }

`
const Headers = styled.h1`
color: rgba(248,232,126, 1);
margin: 0;
font-size: 3em;
`
const Subheads = styled.h1`
color: rgba(248,232,126, 1);
font-size: 1.2em;
`
const Image = styled.img`
height: 480px;
width: auto;
margin: 3vh;
box-shadow: 10px 10px 5px darkgrey;
border-radius: 18%;
`
const AboutContainer = styled.div`
width: 65vw;
`
const Centerer = styled.div`
display: flex;
align-items: center;
flex-direction: column;
height: 100vh;
`
const ButtonContainer = styled.div`
display: flex;
justify-content: space-evenly;
`
const Linkss = styled.a`
width: 50%;
`

const NavLinkk= styled.a`
width: 30%;
margin-left: 3%;
`

export default function HomePage(props) {

    const [clicked, setClicked] = useState();
    const [fun, setFun] = useState(props.fun)
    const [skillsClicked, setSkillsClicked] = useState(false);

    return (
        <Full>
            <Page>
                <Outline>
                    <Box>
                        <Header>Brendan Schatz</Header>
                        <Subhead>Full Stack Developer</Subhead>
                        <Linkss href="#AboutMe"><Button onClick={() => (setClicked(true))}>Learn More <i class="arrow right"></i></Button></Linkss>
                    </Box>
                </Outline>
            </Page>
            <div id="AboutMe" className={clicked ? "scroll" : "SkillsClosed"}>
                <Centerer>
                    <Image src={require("../photos/68725151_2256442834477580_8427649252720640000_o (6).jpg")} />
                    <AboutContainer>
                        <Headers id={clicked ? "scroll" : ""}>About Me</Headers>
                        <Subheads id={clicked ? "scroll" : ""}>    Hello, my name is Brendan Schatz. A little about me; I was going to school for accounting at Eastern Michigan University. After about three years I realised it wasn't the right path for me, I then got the oportunity to go to Lambda School and learn full stack web development. Through years in restauraunt work I learned how to be the best team player possible. Through training for triathlons I learned about the value of hard work. Now as a team lead and student at Lambda I am learning all about time management, leadership, and patience. Looking for the next challenge in my life.</Subheads>
                        <ButtonContainer>
                            <NavLinkk href="/Projects"><Buttons> Projects <i class="arrow left"></i>  </Buttons></NavLinkk>
                            <NavLinkk href="#Skills"><Buttons onClick={() => setSkillsClicked(true)}> Skills <i class="arrow down"></i> </Buttons></NavLinkk>
                            <NavLinkk href="/Contact"><Buttons> Contact Me <i class="arrow right"></i> </Buttons></NavLinkk>
                        </ButtonContainer>
                    </AboutContainer>
                </Centerer>
            </div>
            <div id="Skills" className={skillsClicked ? "SkillsOpen" : "SkillsClosed"}>
                <Centerer>
                    <h1>Skills</h1>
                    <Chart skillsClicked ={skillsClicked}/>
                </Centerer>
            </div>
        </Full>
    )
}