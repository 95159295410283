import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import Hamburger from 'react-hamburger-menu'

import { NavLink } from 'react-router-dom';




const Shimmer = styled.a`
    background-image: -webkit-linear-gradient(left, white 25%, #fde071 37.5%, #ef9671, #996a6e 62.5%, blue 75%);
    background-image: linear-gradient(90deg, white 25%, #fde071 37.5%, #ef9671, #996a6e 62.5%, black 75%);
    background-position: right center;
    background-size: 400% auto;
    -webkit-background-clip: text;
    background-clip: text;
    color: white;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    transition: color 200ms linear;
    font-family: 'Oswald', sans-serif;
    font-size: 2rem;
    margin: 0;
    width: auto;
    text-align: center;
  
@media(max-width: 800px){
    font-size: 1.2rem;
}

  &:hover {
    background-position: left center;
    color: blueviolet;
    transition: background-position 2000ms ease-out;
  }
  
  `

export default function Header(props) {
    const [open, setOpen] = useState(false)

    function activateFun() {
        localStorage.setItem('fun', true)
        window.location.reload(true)
    }

    return (
        <>
        <Hamburger isOpen={open} menuClicked={()=> {setOpen(!open)}} className="hamburger"/>
        
        <div id="nav" className={open? "open" : "closed"}>
            <Shimmer href="#Home" >Home</Shimmer>
            <Shimmer href="#About" >About Me</Shimmer>
            <Shimmer href="#Projects" >Projects</Shimmer>
            <Shimmer href="#Contact" >Contact Me</Shimmer>
            <Shimmer className="shimmer" onClick={() => { activateFun() }}>Activate Fun</Shimmer>

        </div>
        </>
    )
}