import React from 'react';
import {Circle} from './BackgroundStyles'
import styled from 'styled-components'


export default function Background(props){
    
    return(
        <>
        <Circle color={"#4C0099"} height={100} top={props.top === 0? 0 : props.top * .05} left={props.top === 0 ? 0 : props.top * -.1} />
        <Circle color={'#330033'} height={80} top={props.top === 0? 10 : 10 + props.top * -.01} left={props.top === 0 ? 10 : 10 + props.top * -.01} />
        <Circle color={'#660033'} height={60} top={props.top === 0? 20 : 20 + props.top * .01} left={props.top === 0 ? 20 : 20 + props.top * .04} />
        <Circle color={'#190033'} height={40} top={props.top === 0? 30 : 30 + props.top * .07} left={props.top === 0 ? 30 : 30} />
        <Circle color={'#9933FF'} height={20} top={props.top === 0? 40 : 40 + props.top * -.025} left={props.top === 0 ? 40 : 40 + props.top * -.05} />
        </>


    )
}