import React, { useState, useEffect } from "react";
import FunHeader from './funHeader'
import UnfunHeader from './unfunHeader.js'
import NavBar from './navbar2'


export default function Header(props) {
    return (
        <>
            <NavBar setFun={props.setFun} />
        </>
    )

}