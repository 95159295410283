import React, { useState } from 'react';
import '../App.css';
import Background from '../video';
import FunContact from './funContact';
import NoFunContact from './NoFunContact'


export default function Contact() {

    return (
        <>
        {/* {localStorage.getItem("fun") == "true" ? <FunContact /> : <NoFunContact />} */}
        <FunContact />
        </>
    )
}