import React from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import HomePage from './homepage/homePage';
import Header from './Header/Header';
import Middle from './Middle';
import Contact from './Contact/Contact';
import { useState } from 'react';
import Welcome from './Welcome/Welcome'
import { AnimatePresence, motion } from "framer-motion";

function App() {
  const location=useLocation()
  
  return (
    <>

        <Header />
        <AnimatePresence>
          <Switch location={location} key={location.pathname} >
            <Route exact path='/'><Welcome /></Route>
            <Route exact path="/home" ><HomePage /></Route>
            <Route exact path="/Projects" component={Middle} />
            <Route exact path="/Contact" component={Contact} />
          </Switch>
        </AnimatePresence>

    </>
  );
}

export default App;
