import React, { useState, UseEffect } from 'react'
import styled from 'styled-components'
import Slider from 'react-animated-slider'
import 'react-animated-slider/build/horizontal.css'
import '../slide.css'
import SlideShow from '../Projects/slideShow'
import Video from 'react-player'

const Container = styled.div`

  width: 80%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
`


const Center = styled.div`
  color: orange;
  width: 80%;
  height: 100%;

  margin-left: 10%;
`
const Title = styled.h1`
margin: 0;

`
const Technologies = styled.h3`
margin: 2%;

`
const Slides = styled.div`
height: 300px;
`

const Center2 = styled.div`
display:flex;
justify-content: center;

height: 60%;
flex-direction: column;
align-items: center;
`

const Link = styled.a`
color: orange;
text-decoration: none;
font-size: 2rem;


&:hover {
  text-decoration: underline;
  transform: scale(1.2)
}
`

const Links = styled.div`
display: flex;
width: 100%;
justify-content: space-evenly;
`

const projects = [
  { title: 'Chefbook', Technologies: "React, Redux, Styled Components, Node, MySQL.", Description: "Created the entire back end for the project. Collaborated with one other developer on the front end of the project. My focus was predominately on the profiles.", picture1: "./3.PNG", picture2: "./Capture.PNG", picture3: "./Capture2.PNG", deployed: "https://chefstuff-j83fyaiii.now.sh/", github: "https://github.com/bschatzj/chefstuff" },
  { title: 'Poker Battles', Technologies: "React, Redux, Socket.io, Node, PostgreSQL.", Description: "Ongoing personal project of mine. Using websockets to set up a one on one poker game. Once a hand is played it is logged in a database and saved to each users account.", picture1: "./1.PNG", picture2: "./2.PNG", picture3: "./4.PNG", deployed: "https://github.com/bschatzj/poker-fe", github: "https://github.com/bschatzj/poker-fe" },
  { title: 'Foster Together', Technologies: "React, Node, PostgreSQL, React table, MapBox, AuthO, Styled Components, Formik.", Description: "Worked with a team of four other developers and two UX designers on this greenfield project. Created a fully functional dashboard, training, map, and profiles for our client. My primary role was working on the front end. I was responsible for the admin dashboard, map, training modules, and sign up form.", video: true, picture1: null, picture2: null, picture3: null, deployed: null, github: "https://github.com/FosterPlayground" },

]

export default function Carosel() {


  return (
    <Container>
      <Slider >
        {projects.map((slide, index) => (
          <Center key={index}>
            <Title>{slide.title}</Title>
            <Technologies>{slide.Technologies} </Technologies>
        {slide.picture1 ? <Slides><SlideShow picture1={slide.picture1} picture2={slide.picture2} picture3={slide.picture3} /> <Technologies>{slide.Description}</Technologies> <Links><Link href={slide.deployed}>Deployment</Link>  <Link href={slide.github}>Code</Link> </Links> </Slides> : <> </>}
            {slide.video ? <Center2><Video url="https://www.youtube.com/embed/lDTMVymPHxQ" controls={true} width={"80%"} height={"100%"} /> <Technologies>{slide.Description}</Technologies> <Links><Link href={slide.deployed}>Deployment</Link>  <Link href={slide.github}>Code</Link> </Links> </Center2> : null}
          </Center>
        ))}
      </Slider>
    </Container>
  )
}
