import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from 'styled-components'

const Label = styled.label`
color: white;
padding: .5%;
`
const Container = styled.div`
padding: 4%;
margin-top: 4%;
`
const Message = styled.h1`
color: red;
text-align: center;
`
const Button = styled.button`
margin-top: 3rem;
width: 30%;
height: 60px;
font-size: 2rem;
color: white;
background-color: rgb(0,0,0,0);
border: 3px solid white;
&:hover{
    color: black;
    background-color: white;
}
`
function MyForm() {
    const thunder = new Audio("http://soundbible.com/grab.php?id=302&type=mp3")
    /* NEW: validation for inputs vvvv */
    const [inputs, setInputs] = useState({
        name: "",
        email: "",
        message: ""
    });
    const [fieldErrors, setFieldErrors] = useState({});

    const validate = () => {
        let errors = {};
        let hasErrors = false;
        for (let key of Object.keys(inputs)) {

            hasErrors |= errors[key];
        }
        setFieldErrors(prev => ({ ...prev, ...errors }));
        return !hasErrors;
    };
    useEffect(() => {
        // Only perform interactive validation after submit
        if (Object.keys(fieldErrors).length > 0) {
            validate();
        }
    }, [inputs]);
    /* End new validation ^^^^ */

    // Input Change Handling
    const handleOnChange = event => {
        event.persist();
        setInputs(prev => ({
            ...prev,
            [event.target.id]: event.target.value
        }));
    };

    // Server State Handling
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const handleServerResponse = (ok, msg) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            setFieldErrors({});
            setInputs({
                name: "",
                email: "",
                message: ""
            });
        }
    };
    const handleOnSubmit = event => {
        event.preventDefault();
        if (!validate()) {
            return;
        }
        setServerState({ submitting: true });
        axios({
            method: "POST",
            url: "https://formspree.io/myykkgjg",
            data: inputs
        })
            .then(r => {
                setInputs({
                    name: "",
                    email: "",
                    message: ""
                })
                localStorage.setItem('Email', Date.now())
                localStorage.removeItem('Failed')
            })
            .catch(r => {
                localStorage.setItem('Failed', Date.now())
                handleServerResponse(false, r.response.data.error);
            });
    };


    return (
        <Container>
            {localStorage.getItem('Failed') ? <Message>Failed to send. Please try again!</Message> : null}
            {localStorage.getItem('Email') ? <Message>Success. Looking forward to talking with you! </Message> : null}
            <form className="form-container" onSubmit={handleOnSubmit}>
                <Label className="labels">Name:</Label>
                <input class="small-input" type="text" id="name" value={inputs.name} onChange={handleOnChange} />
                <Label className="labels">Email:</Label>
                <input class="small-input" type="email" id="email" value={inputs.email} onChange={handleOnChange} />
                <Label className="labels">Message:</Label>
                <textarea className="big-input" id="message" value={inputs.message} onChange={handleOnChange} />
                <Button type="submit" >Send</Button>
            </form>
        </Container>
    );
};

export default MyForm