import React, { useState } from 'react'
import styled from 'styled-components'
import Video from 'react-player'
import './projects.css'
import SlideShow from './slideShow'

const Section = styled.section`
margin-top: 10rem;
border: 4px solid black;
width: 60%;
padding: 3% 11%;
@media(max-width: 800px){
    font-size: 1.2rem;
    width: 80%;
    padding: 3% 6%;
}
`

const ProjectsHeader = styled.h1`
font-size: 3rem;
text-decoration: underline;
text-align: center;
margin-top: 0;
padding: 0;
color: white;
`
const Project = styled.div`
width: 100%;
background-color: white;
margin-top: 4rem;
`
const ProjectTitle = styled.h1`
color: blue;
font-size: 3rem;
text-decoration: underline;
`
const VideoContain = styled.div`
display: flex;
justify-content: center;
width: 100%;
height: 500px;
`

const ProjectDescription = styled.h3`
font-size: 1.5rem;
`

export default function Projects() {
    const [project1, setProject1] = useState(false)
    const [project2, setProject2] = useState(false)
    const [project3, setProject3] = useState(false)
    const [project4, setProject4] = useState(false)
    return (
        <Section>
            <ProjectsHeader>Some of my past work...</ProjectsHeader>
            <Project className="project">
                <ProjectTitle>Foster Together</ProjectTitle>
                <ProjectDescription >Technologies: React, Node, PostgreSQL, React table, MapBox, AuthO, Styled Components, Formik. </ProjectDescription>
                <ProjectDescription> Worked with a team of four other developers and two UX designers on this greenfield project. Created a fully functional dashboard, training, map, and profiles for our client. My primary role was working on the front end. I was responsible for the admin dashboard, map, training modules, and sign up form. </ProjectDescription>
                {/* <div className="project" className={project1 ? "open" : "closed"}> */}
                <VideoContain>
                    <Video url="https://www.youtube.com/embed/lDTMVymPHxQ" controls={true} width={"80%"} height={"90%"} />
                </VideoContain>
                <div className="moreInfo">
                    <a href="https://github.com/FosterPlayground" target="blank"> <img src={require('../photos/Github.svg')} className="logo" alt="github logo" /> </a>
                    <ProjectDescription> Not Currently Deployed.</ProjectDescription>
                </div>
                {/* </div> */}
                {/* <button onClick={() => { setProject1(!project1) }}> {project1 ? "See Less" : "See More"} </button> */}
            </Project>
            <Project>
                <ProjectTitle>Chefbook</ProjectTitle>
                <ProjectDescription>React, Redux, Styled Components, Node, MySQL.</ProjectDescription>
                <ProjectDescription>Created the entire back end for the project. Collaborated with one other developer on the front end of the project. My focus was predominately on the profiles.</ProjectDescription>
                <SlideShow picture1={'./3.PNG'} picture2={'./Capture.PNG'} picture3={'./Capture2.PNG'} />
                <div className="moreInfo">
                    <a href="https://github.com/bschatzj/chefstuff" target="blank"> <img src={require('../photos/Github.svg')} className="logo" alt="github logo" /> </a>
                    <a style={{ textDecoration: 'none' }} href="https://chefstuff-j83fyaiii.now.sh/" target="blank"><div className="logoBig"><img src={require('./favicon.ico')} />  <h3 className="deployed">Deployed Site</h3></div></a>
                </div>
            </Project>
            <Project>
                <ProjectTitle>Poker Battles</ProjectTitle>
                <ProjectDescription> React, Redux, Socket.io, Node, PostgreSQL.</ProjectDescription>
                <ProjectDescription> Ongoing personal project of mine. Using websockets to set up a one on one poker game. Once a hand is played it is logged in a database and saved to each users account.</ProjectDescription>
                <SlideShow picture1={'./1.PNG'} picture2={'./2.PNG'} picture3={'./4.PNG'} />

                <div className="moreInfo">
                    <a href="https://github.com/bschatzj/poker-fe" target="blank"> <img src={require('../photos/Github.svg')} className="logo" alt="github logo" /> </a>
                    <a style={{ textDecoration: 'none' }} href="https://poker-lq4f6laa9.vercel.app/" target="blank"><div className="logoBig"><img className="poker-logo" src={require('./chip.jpg')} />  <h3 className="deployed">Deployed Site</h3></div></a>
                </div>
            </Project>
            {/* <Project>
                <ProjectTitle>Sleeptrack</ProjectTitle>
            </Project> */}
        </Section>
    )
}