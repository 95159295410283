import styled from 'styled-components'


  export  const Circle =styled.div`
    position: fixed;
    border-radius: 100%;
    width: ${props => props.height}%;
    height: ${props =>  props.height}%;
    background-color: ${props =>  props.color};
    z-index: -9;
    top: ${props =>  props.top}%;
    left: ${props =>  props.left}%;
    
    `
